import React, { useState } from "react"
import Layout from "../layout"
import PageTitle from "../components/page-title"
import Panel from "../components/panel"
import PanelContent from "../components/panel/content"
import PanelTitle from "../components/panel/title"
import Seo from "../components/seo"
import meta from "../meta-constants"

function Base64Encoder() {
  const { title, description } = meta.base64encoder
  const [formData, setFormData] = useState({
    encoded: "",
    decoded: "",
  })

  const onChange = e => {
    setFormData(formData => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const encode = e => {
    e && e.preventDefault()
    let encodedStr = ""
    try {
      encodedStr = btoa(formData.decoded)
    } catch (err) {
      encodedStr = "Failed to encode: " + err.message
    }
    setFormData(formData => ({
      ...formData,
      encoded: encodedStr,
    }))
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <PageTitle titleText={title} />
      <Panel>
        <PanelTitle titleText="Convert Plain Text to Base64 Encoded Text" />
        <PanelContent>
          <form id="form">
            <label htmlFor="decoded">
              Enter plain text below and click Encode button :
            </label>
            <textarea
              id="decoded"
              required="required"
              className="form-control"
              name="decoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.decoded}
            ></textarea>

            <br />
            <span
              tabIndex="0"
              role="button"
              className="btn btn-primary"
              onClick={encode}
              onKeyDown={encode}
            >
              Encode
            </span>

            <br />
            <br />

            <label htmlFor="encoded">
              Encoded text will be shown in below text box :
            </label>
            <textarea
              id="encoded"
              required="required"
              className="form-control"
              name="encoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.encoded}
            ></textarea>
          </form>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"About " + title} />
        <PanelContent>
          <p>{description}</p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="What is Base64?" />
        <PanelContent>
          <p>
            In programming, Base64 is a group of binary-to-text encoding schemes
            that represent binary data (more specifically, a sequence of 8-bit
            bytes) in an ASCII string format by translating the data into a
            radix-64 representation. The term Base64 originates from a specific
            MIME content transfer encoding. Each non-final Base64 digit
            represents exactly 6 bits of data. Three 8-bit bytes (i.e., a total
            of 24 bits) can therefore be represented by four 6-bit Base64
            digits.
          </p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="How to use Base64 Encoder Online Tool" />
        <PanelContent>
          <p>
            Use below steps to encode plain text to a base64 encoded string:
          </p>
          <ol>
            <li>Open Base64 Encoder Online Tool</li>
            <li>Enter text in first text box</li>
            <li>Click on Encode button</li>
            <li>Check the second text box for base64 encoded output</li>
          </ol>
        </PanelContent>
      </Panel>
    </Layout>
  )
}

export default Base64Encoder
